import React, { useState , useEffect } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";

const ProgressStepBar = ({step}) => {
  const [progress, setProgress] = useState(67);

  useEffect(() => {
    if(step === 0 || step === 1 ){
      setProgress(1);
    } else if (step === 2) {
      setProgress(34)
    } else if (step === 3 ) {
      setProgress(67)
    } else if (step > 3) {
      setProgress(100)
    }
  }, [step]);

  return (
    <div className=" flex justify-center">
      <div className="w-11/12">
        <ProgressBar percent={progress} height={3} unfilledBackground="#DDDDDD" filledBackground="#3643BA" >
          <Step>
            {({ accomplished }) => (
              <>
                <div
                  className={`w-[28px] h-[28px] bg-[#CDDAF8] flex rounded-full justify-center items-center relative ${accomplished ? "accomplished" : null
                    }`}
                >
                  <span className={`w-1.5 h-1.5 rounded-full ${accomplished ? "bg-[#3643BA]" : "bg-[#999999]"}`} />
                  <div className={`absolute top-10 text-sm ${accomplished ? "text-[#3643BA]" : ''}`}>Üretiliyor</div>
                </div>
              </>
            )}
          </Step>
          <Step>
            {({ accomplished }) => (
              <>
                <div
                  className={`w-[28px] h-[28px] bg-[#CDDAF8] flex rounded-full justify-center items-center relative ${accomplished ? "accomplished" : null
                    }`}
                >
                  <span className={`w-1.5 h-1.5 rounded-full ${accomplished ? "bg-[#3643BA]" : "bg-[#999999]"}`} />
                  <div className={`absolute top-10 text-sm whitespace-nowrap ${accomplished ? "text-[#3643BA]" : ''}`}>Sevk Aşaması</div>
                </div>
              </>
            )}
          </Step>
      
          <Step>
            {({ accomplished }) => (
              <>
                <div
                  className={`w-[28px] h-[28px] bg-[#CDDAF8] flex rounded-full justify-center items-center relative ${accomplished ? "accomplished" : null
                    }`}
                >
                  <span className={`w-1.5 h-1.5 rounded-full ${accomplished ? "bg-[#3643BA]" : "bg-[#999999]"}`} />
                  <div className={`absolute top-10 text-sm ${accomplished ? "text-[#3643BA]" : ''}`}>Depoda</div>
                </div>
              </>
            )}
          </Step>

          <Step>
            {({ accomplished }) => (
              <>
                <div
                  className={`w-[28px] h-[28px] bg-[#CDDAF8] flex rounded-full justify-center items-center relative ${accomplished ? "accomplished" : null
                    }`}
                >
                  <span className={`w-1.5 h-1.5 rounded-full ${accomplished ? "bg-[#3643BA]" : "bg-[#999999]"}`} />
                  <div className={`absolute top-10 text-sm ${accomplished ? "text-[#3643BA]" : ''}`}>Dağıtımda</div>
                </div>
              </>
            )}
          </Step>

        </ProgressBar>
      </div>
    </div>
  );
}

export default ProgressStepBar;