import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Skeleton } from "../components/ui/skeleton";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import { RadioGroup, RadioGroupItem } from "../components/ui/radio-group";

import { ToggleGroup, ToggleGroupItem } from "../components/ui/toggle-group";
import { get, set } from "../services/cache";
import config from "../services/config";
import HamburgerMenuIcon from "../assets/hamburgerMenu.svg";
import rightArrow from "../assets/rightArrow.svg";
import completedOrders from "../assets/completeOrders.svg";
import pendingOrders from "../assets/pendingOrders.svg";
import leftArrow from "../assets/leftArrow.svg";
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Layout() {
  const claims = useSelector((state) => state.user.claims.name || "");
  const [hasOrderUrl, setHasOrderUrl] = useState(false);
  const [name, setName] = useState(" ");
  const location = useLocation();
  const path = location.pathname;
  const userAccessToken = get("fedidLoginAccessToken");
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("typeOfList") || "ordersInProgress"
  );

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("/order")) {
      setHasOrderUrl(true);
    } else {
      setHasOrderUrl(false);
    }
  }, [path]);

  useEffect(() => {
    if (get("claims")?.data?.name) {
      setName(get("claims")?.data.name);
    } else if (get("claims")?.data?.display_name) {
      setName(get("claims")?.data?.display_name);
    }

    const interval = setInterval(() => {
      const newValue = get("claims")?.data?.name;
      if (newValue && newValue !== name) {
        setName(newValue);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [claims, name]);

  const handleValueChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <div className="border w-full p-4 flex">
      <Sheet>
        {hasOrderUrl ? (
          <>
            <button
              className="buton w-[32px] h-[33px] flex justify-center items-center"
              onClick={() => window.history.back()}
            >
              <svg
                width="11"
                height="19"
                viewBox="0 0 11 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.28328 0.796387L10.6975 2.2106L3.4046 9.50349L10.6975 16.7964L9.28328 18.2106L0.576172 9.50349L9.28328 0.796387Z"
                  fill="#101010"
                />
              </svg>
              {/* 
              <img
                src={leftArrow}
                alt="menu"
              /> */}
            </button>
          </>
        ) : (
          <>
            <SheetTrigger asChild>
              <button className="buton" onClick={()=> {setSelectedValue(localStorage.getItem("typeOfList"))}}>
                <img src={HamburgerMenuIcon} alt="menu" />
              </button>
            </SheetTrigger>
          </>
        )}
        {/* <SheetTrigger asChild>
          <button className="buton">
            <img src={HamburgerMenuIcon} alt="menu" />
          </button>
        </SheetTrigger> */}
        <SheetContent side="left" className="py-4 px-0">
          <SheetHeader className="flex flex-row space-y-0">
            <SheetClose asChild>
              <button className="navButton px-4">
                <img src={HamburgerMenuIcon} alt="menu" />
              </button>
            </SheetClose>
            <span className=" text-lg ml-6 align-center">Siparişlerim</span>
          </SheetHeader>
          {/* <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Name
              </Label>
              <Input id="name" value="Pedro Duarte" className="col-span-3" />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="username" className="text-right">
                Username
              </Label>
              <Input id="username" value="@peduarte" className="col-span-3" />
            </div>
          </div> */}
          <SheetFooter>
            {/* <SheetClose asChild className="flex flex-col"> */}
            {/* <ToggleGroup type="single" className="flex flex-col mt-2">
              <ToggleGroupItem
                value="completedOrders"
                className="w-full text-[#3643BA] flex justify-between py-[30px]"
                onClick={() =>
                  localStorage.setItem("typeOfList", "completedOrders")
                }
                checked={true}
              >
                <div className="flex items-center gap-2">
                  <img src={completedOrders} alt="" className="" /> Tamamlanan
                  Siparişler{" "}
                </div>{" "}
                <img src={rightArrow} alt="" />
              </ToggleGroupItem>
              <ToggleGroupItem
                value="ordersInProgress"
                className="w-full text-[#3643BA] flex justify-between py-[30px]"
                onClick={() =>
                  localStorage.setItem("typeOfList", "ordersInProgress")
                }
              >
                <div className="flex items-center gap-2">
                  <img src={pendingOrders} alt="" className="" /> Beklemedeki
                  Siparişler
                </div>
                <img src={rightArrow} alt="" />
              </ToggleGroupItem>
            </ToggleGroup> */}
            <RadioGroup value={selectedValue} onValueChange={handleValueChange}>
              <div className="flex items-center p-3 py-[20px] space-x-2">
                <RadioGroupItem
                  value="completedOrders"
                  id="completedOrders"
                  onClick={() => {
                    localStorage.setItem("typeOfList", "completedOrders");
                    window.location.reload();
                  }}
                />
                  <img src={completedOrders} alt="" className="absolute !ml-4 left-0" />
                <Label htmlFor="completedOrders" className={`flex items-center justify-around !ml-4  ${selectedValue === "completedOrders" ? 'text-[#3643BA]' : ''}`}>
                  Tamamlanan Siparişler
                  <img src={rightArrow} alt="" />
                </Label>
              </div>
              <div className="flex items-center px-3 py-[20px] space-x-2">
                <RadioGroupItem
                  value="ordersInProgress"
                  id="ordersInProgress"
                  onClick={() => {
                    localStorage.setItem("typeOfList", "ordersInProgress");
                    window.location.reload();
                  }}
                  className={`${selectedValue === "ordersInProgress" ? 'bg-[#999999]' : ''}`}
                />
                  <img src={pendingOrders} alt="" className="absolute !ml-4 left-0" />
                <Label htmlFor="ordersInProgress" className={`flex items-center justify-around !ml-4  ${selectedValue === "ordersInProgress" ? 'text-[#3643BA]' : ''}`}>
                  Beklemedeki Siparişler
                  <img src={rightArrow} alt="" />
                </Label>
              </div>
            </RadioGroup>
            {/* </SheetClose> */}
          </SheetFooter>
        </SheetContent>

      </Sheet>
      <div className="ml-6 text-xl self-center flex gap-3">
        {hasOrderUrl
          ? "Sipariş Detayı"
          : `Merhaba ${name ? name : <Skeleton className=" w-24 " />}`}
      </div>
    </div>
  );
}
