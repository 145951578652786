import config from "./config";
import { get } from "./cache";
import eventEmitter from "./eventEmitter"; // EventEmitter'ı import edin

const BASE_URL = config.baseUrl;

// İlk başta token'ı alalım
let userAccessToken = get("fedidLoginAccessToken");
let memberAccessToken = localStorage.getItem("access_token");

// Eğer userAccessToken null veya undefined ise, boş bir nesne olarak başlatın
let proxyUserAccessToken = {};

if (userAccessToken) {
  proxyUserAccessToken = new Proxy(userAccessToken, {
    set: function (obj, prop, value) {
      if (prop === 'data' && obj[prop]?.access_token !== value?.access_token) {
        obj[prop] = value;
        eventEmitter.emit("tokenUpdated", obj);
      } else {
        obj[prop] = value;
      }
      return true;
    }
  });
}

// Token değiştiğinde çalışacak olay dinleyicisini ekleyin
eventEmitter.on("tokenUpdated", (newToken) => {
  console.log("Token updated:", newToken);
  // Gerekli başka işlemleri burada yapabilirsiniz
});

const apiService = {
  get: async (endpoint) => {
    // Her API çağrısından önce token'ı güncelleyin
    if (userAccessToken !== get("fedidLoginAccessToken")) {
      userAccessToken = get("fedidLoginAccessToken");
      if (userAccessToken) {
        proxyUserAccessToken = new Proxy(userAccessToken, {
          set: function (obj, prop, value) {
            if (prop === 'data' && obj[prop]?.access_token !== value?.access_token) {
              obj[prop] = value;
              eventEmitter.emit("tokenUpdated", obj);
            } else {
              obj[prop] = value;
            }
            return true;
          }
        });
      }
    }

    const response = await fetch(`${BASE_URL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${proxyUserAccessToken?.data?.access_token ? proxyUserAccessToken?.data?.access_token : memberAccessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  },

  getMember: async (endpoint, token) => {
    const response = await fetch(`${BASE_URL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  },

  post: async (endpoint, data) => {
    // Her API çağrısından önce token'ı güncelleyin

    if (userAccessToken !== get("fedidLoginAccessToken")) {
      userAccessToken = get("fedidLoginAccessToken");
      if (userAccessToken) {
        proxyUserAccessToken = new Proxy(userAccessToken, {
          set: function (obj, prop, value) {
            if (prop === 'data' && obj[prop]?.access_token !== value?.access_token) {
              obj[prop] = value;
              eventEmitter.emit("tokenUpdated", obj);
            } else {
              obj[prop] = value;
            }
            return true;
          }
        });
      }
    }

    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${proxyUserAccessToken?.data?.access_token ? proxyUserAccessToken?.data?.access_token : memberAccessToken}`,
        'X-Correlation-ID': '554d1917-0099-49cc-801f-471d98d1c1c1',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  },

  // PUT, DELETE ve diğer HTTP metodları için benzer fonksiyonlar ekleyebilirsiniz
};

export default apiService;
