import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../pages/login/Login";
import { FedidLogin } from "../pages/login/FedidLogin";
import { SupplierLogin } from "../pages/login/SupplierLogin";
import Layout from "../pages/Layout";
import HomePage from "../pages/home/HomePage";
import OrderDetail from "../pages/details/OrderDetail";
import { Toaster } from "../components/ui/sonner";
import { get } from "../services/cache";

const MasterRouter = () => {
  const [showLayout, setShowLayout] = useState();

  // useEffect(() => {
  //   if (
  //     ((window.location.pathname !== "/" &&
  //       window.location.pathname !== "/supplier-login") ||
  //     window.location.pathname === "/homepage") && get("type")?.data === "supplier"
  //   ) {
  //     setShowLayout(true);
  //   }
  // }, [showLayout]);

  useEffect(() => {
    const fetchCache = async () => {
      if (
        ((window.location.pathname !== "/" &&
          window.location.pathname !== "/supplier-login") ||
          window.location.pathname === "/homepage") &&
        (get("type")?.data === "supplier" || get("type")?.data !== "supplier")
      ) {
        setShowLayout(true);
      }
    };

    fetchCache();

    const interval = setInterval(fetchCache, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Router>
      {/* {window.location.pathname === ('Supplierlogin', '/') && <Layout />} */}
      {showLayout ? <Layout /> : <></>}
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<FedidLogin />} />
        <Route exact path="/supplier-login" element={<SupplierLogin />} />
        <Route exact path="/homepage" element={<HomePage />} />
        <Route path="/order/:orderNumber" element={<OrderDetail />} />{" "}
        {/* Ürün detay sayfası için yol tanımı */}
      </Routes>
      <Toaster />
    </Router>
  );
};

export default MasterRouter;
