import React, { useEffect, useState } from "react";
import { get, set } from "../../services/cache";
import config from "../../services/config";
import { useNavigate } from "react-router-dom";
import { dateAdd } from "../../services/utils";
import { useDispatch } from "react-redux";
import { setClaims } from "../../features/user/userSlice";

export const FedidLogin = () => {
  const redirect = useNavigate();
  const dispatch = useDispatch();

  const redirectHome = () => {
    if (get("claims")) {
      redirect("/homepage");
    }
  };

  let code = new URL(window.location.href).searchParams.get("code");

  const code_verifier = get(config.FedidCodeVerifier.cacheKey);
  const [name, setName] = useState();

  useEffect(() => {
    const urlDecoded = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_CLIENTID,
      code,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      code_verifier: code_verifier?.data,
    };

    var urlEncoded = [];
    for (var property in urlDecoded) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(urlDecoded[property]);
      urlEncoded.push(encodedKey + "=" + encodedValue);
    }

    let body = urlEncoded.join("&");

    fetch(process.env.REACT_APP_FEDID_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body,
    })
      .then((response) => response.json())
      .then((res) => {
        set({
          key: config.FedidLogin.cacheKey,
          data: res,
          expires: dateAdd(new Date(), "second", 7199).toISOString(),
        });
        fetch(
          `${config.baseUrl}/v1/user/fedid-info`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${res.access_token}`,
              "x-api-key": config.apiKey,
            },
            credentials: "same-origin",
          }
        )
          .then((response) => response.json())
          .then((result) => {
            // localStorage.setItem('claimsData',(result));
            dispatch(setClaims(result));
            set({
              key: "claims",
              data: result.data,
            });
            redirect("/homepage")
          })
          .catch((error) => {
            console.log(error);
          });
      });
  }, []);

  return <div></div>;
};
