/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const PhotoGallery = ({ photos, onDeletePhoto }) => {
  return (
    <>
      {photos.map((photo, index) => (
        <div className="photo-container relative w-max">
          <img
            key={index}
            src={URL.createObjectURL(photo)}
            alt={`Photo ${index + 1}`}
            style={imageStyles}
            className="border my-1 w-[100px] h-[100px] object-cover"
          />
          <button
            onClick={() => onDeletePhoto(index)}
            style={deleteButtonStyles}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-[10px] h-[10px]"
            >
              <path
                d="M9.00096 7.11562L15.601 0.515625L17.4863 2.40096L10.8863 9.00096L17.4863 15.601L15.601 17.4863L9.00096 10.8863L2.40096 17.4863L0.515625 15.601L7.11562 9.00096L0.515625 2.40096L2.40096 0.515625L9.00096 7.11562Z"
                fill="#000"
              />
            </svg>
          </button>
        </div>
      ))}
    </>
  );
};

const imageStyles = {
  width: "100px",
  height: "100px",
  objectFit: "cover",
  borderRadius: "4px"
};

const deleteButtonStyles = {
  position: "absolute",
  top: "6px",
  right: "1px",
  background: "white",
  border: "1px solid",
  borderRadius: "25px",
  padding: "5px",
  cursor: "pointer",
};
export default PhotoGallery;
