import React from "react";
import { Card, CardTitle, CardHeader } from "../../components/ui/card";
// import plvLogo from "../../assets/plvLogo.svg";
import { Skeleton } from "../../components/ui/skeleton";

const LoadingCard = () => {
  return (
    <div className="flex">
      <div className="w-3 rounded-l-lg border mb-3 bg-white" />

      <Card className="border w-full rounded-l-none border-l-0 mb-3 cursor-pointer flex flex-col">
        <CardHeader className="flex-row justify-between items-center py-3 px-0 border border-[#999999] border-dashed border-x-0 border-t-0 mx-3">
          <CardTitle className="text-base">
            <Skeleton className="h-5 w-[120px]" />
          </CardTitle>
          <Skeleton className="h-5 w-[150px] " />
        </CardHeader>
        <div className="p-3" type="single">
          <div className="flex mts-4 space-x-4 w-full">
            <div className="flex-shrink-0">
              {/* <img src={plvLogo} alt="" className="w-[80px] h-[80px]" /> */}
              <Skeleton className="h-[80px] w-[80px] " />
            </div>
            <div className="flex-grow">
              <h3 className="text-sm font-semibold text-gray-900">
                <Skeleton className="h-5 w-[150px]" />
              </h3>
              <p className="text-gray-700 text-sm">
                <Skeleton className="h-5 w-[80px] mt-1 " />
              </p>
            </div>
            <div className="flex-grow flex flex-col justify-between items-end ml-0">
              <Skeleton className="h-5 w-[80px] " />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LoadingCard;
