import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import {
  Command,
  CommandGroup,
  CommandEmpty,
  CommandItem,
  CommandInput,
} from "../../components/ui/command";
import React, { useState, useRef, useEffect } from "react";
import OrderCard from "./OrderCard";
import LoadingCard from "./LoadingCard";
import returnIcon from "../../assets/returnIcon.svg";
import closeIcon from "../../assets/closeIcon.svg";
import downArrow from "../../assets/downArrow.svg";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import config from "../../services/config";
import { get } from "../../services/cache";
import apiService from "../../services/apiService";
import { toast } from "sonner";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import csvDownload from "../../assets/csvDownload.svg";

import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const [isActive, setIsActive] = useState(false);
  const [pendingOrders, setPendingOrders] = useState();
  const [completeOrders, setCompleteOrders] = useState();
  const [tabChange, setTabChange] = useState(false);
  const [multiSelect, setMultiSelect] = useState("Statü Belirle");
  const myElementRef = useRef(null);
  const [value, setValue] = useState(
    localStorage.getItem("typeOfList") || "ordersInProgress"
  );
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSearch, setOpenSearch] = useState(get("claims"));
  const userAccessToken = get("fedidLoginAccessToken");

  //////// TEST VARIABLES

  const [filter, setFilter] = useState("");
  const [orders, setOrders] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsSelect, setSuggestionsSelect] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkRole, setCheckRole] = useState("");

  /////////////////
  const redirect = useNavigate();

  const handleToggleActivation = () => {
    setIsActive(!isActive);
  };

  const [checked, setChecked] = useState(false);

  const toggleCheckbox = () => {
    setChecked(!checked);
  };

  /////////////// TEST KOD

  const [selectedIds, setSelectedIds] = useState([]);
  const [isSelectionActive, setIsSelectionActive] = useState(false);
  const [expandedIds, setExpandedIds] = useState([]);

  const handleCardClick = (productOrderNumber) => {
    if (isSelectionActive) {
      if (selectedIds.includes(productOrderNumber)) {
        setSelectedIds(
          selectedIds.filter(
            (orderNumber) => orderNumber !== productOrderNumber
          )
        );
      } else {
        setSelectedIds([...selectedIds, productOrderNumber]);
      }
    }
  };

  const toggleSelection = () => {
    setIsSelectionActive(!isSelectionActive);
    setSelectedIds([]);
    if (!isSelectionActive) {
      setExpandedIds([]);
    }
  };

  const toggleAccordion = (productId) => {
    if (expandedIds.includes(productId)) {
      setExpandedIds(expandedIds.filter((id) => id !== productId));
    } else {
      setExpandedIds([...expandedIds, productId]);
    }
  };

  const handleSubmit = async (e) => {
    // alert(selectedIds);
    setIsSelectionActive(false);

    const body = {
      orderNumbers: selectedIds,
      status: e,
    };

    try {
      const result = await apiService.post(`/v1/order/set-status`, body);
      if (result.isSuccess) {
        toast("Toplu Statü Güncellendi", {
          description: `Güncellenen Siparişler: ${selectedIds}.`,
          cancel: {
            label: "Kapat",
          },
        });
        setPendingOrders([]);
        setCompleteOrders([]);
        refreshOrders();
      } else {
        console.error("Error Message:", result.errorMessage);
        toast("Toplu Statü Güncelleme Hatası", {
          description: `Toplu Statü Güncellenirken hata oluştu.`,
          cancel: {
            label: "Kapat",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  ///////// TEST KOD SONU

  const pendingData = async () => {
    try {
      // const result = await apiService.get("/v1/order/get-uncompleted-orders");
      // const result = await apiService.get(`/v1/order/${get("type").data === "supplier" ? `get-uncompleted-orders?filter=${get("claims").data.email}` : `get-uncompleted-orders?filter=${get("claims").data.site}`}`);
      const result = await apiService.get(
        `/v1/order/get-uncompleted-orders?filter=${
          (get("claims")?.data?.jobTitle !== "Software Development Engineer" && get("claims")?.data?.jobTitle !== 'CHEF DE PROJET' && get("claims")?.data?.jobTitle !== 'Merchandiser')
            ? get("type")?.data === "supplier"
              ? get("claims").data.email
              : get("claims").data.site
            : ""
        }`
      );
      setPendingOrders(result);
      setOrders(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
    setSuggestionsSelect(true);
  };

  useEffect(() => {
    if (filter) {
      const newSuggestions =
        localStorage.getItem("TypeOfList") !== "completedOrders"
          ? pendingOrders.filter(
              (order) =>
                order.orderNumber?.includes(filter) ||
                order.supplierEmail
                  ?.toLowerCase()
                  .includes(filter.toLowerCase())
            )
          : completeOrders?.filter(
              (order) =>
                order.orderNumber?.includes(filter) ||
                order.supplierEmail
                  ?.toLowerCase()
                  .includes(filter.toLowerCase())
            );
      setSuggestions(newSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [filter, orders]);

  const handleSuggestionClick = (suggestion) => {
    setFilter(suggestion.orderNumber); // Veya suggestion.details
    setSuggestions([]);
    setSuggestionsSelect(false);
  };

  const filteredOrders = pendingOrders?.filter(
    (order) =>
      order.orderNumber?.includes(filter) ||
      order.supplierEmail?.toLowerCase().includes(filter.toLowerCase())
  );

  const completedFilteredOrders = completeOrders?.filter(
    (order) =>
      order.orderNumber?.includes(filter) ||
      order.supplierEmail?.toLowerCase().includes(filter.toLowerCase())
  );

  const completedData = async () => {
    try {
      // const result = await apiService.get("/v1/order/get-completed-orders");
      // const result = await apiService.get(`/v1/order/${get("type").data === "supplier" ? `get-completed-orders?filter=${get("claims").data.email}` : `get-completed-orders?filter=${get("claims").data.site}` }`);
      const result = await apiService.get(
        `/v1/order/get-completed-orders?filter=${
          (get("claims")?.data?.jobTitle !== "Software Development Engineer" && get("claims")?.data?.jobTitle !== 'CHEF DE PROJET' && get("claims")?.data?.jobTitle !== 'Merchandiser')
            ? get("claims")?.data.jobTitle
              ? get("claims").data.site
              : get("claims").data.email
            : ""
        }`
      );
      setCompleteOrders(result);
      setOrders(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    pendingData();
    completedData();

    const element = myElementRef.current;
    // Observer
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-state"
        ) {
          element.getAttribute("data-state") === "inactive"
            ? setTabChange(true)
            : setTabChange(false);
        }
      });
    });

    if (element) {
      observer.observe(element, {
        attributes: true,
        attributeFilter: ["data-state"],
      });
    }

    localStorage.setItem("typeOfList", value);

    return () => {
      observer.disconnect();
    };
  }, [tabChange]);

  useEffect(() => {
    localStorage.setItem("typeOfList", value);
    if (value === "ordersInProgress") {
      setTabChange(false);
    } else {
      setTabChange(true);
    }
  }, [value]);

  const refreshOrders = () => {
    pendingData();
    completedData();
  };

  const pendingFilteredOrders = pendingOrders?.filter(
    (order) =>
      order.orderNumber.includes(searchTerm) ||
      order.supplierEmail.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // const handleFilterChange = (e) => {
  //   setSearchTerm(e);
  // };

  const handleCSV = async () => {
    function getCurrentDate() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, "0");
      const month = String(today.getMonth() + 1).padStart(2, "0"); // Ocak ayı 0 olduğu için 1 ekliyoruz
      const year = today.getFullYear();

      return `${day}.${month}.${year}`;
    }
    try {
      const response = await fetch(
        `${config.baseUrl}/v1/order/get-completed-orders-as-csv`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userAccessToken.data.access_token}`,
            "x-api-key": config.apiKey,
          },
          credentials: "same-origin",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `completed_orders_${getCurrentDate()}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  useEffect(() => {
    const role = get("claims")?.data?.jobTitle;
    if (role === "Software Development Engineer" || role === 'CHEF DE PROJET' || role === 'Merchandiser') {
      setCheckRole("Admin");
    } else if (role === "Store" || get("claims")?.data?.hasDuty === true) {
      setCheckRole("store");
    } else if (role === "warehouse") {
      setCheckRole("warehouse");
    } else if (role?.data?.email) {
      setCheckRole("supplier");
    }
  }, []);

  return (
    <div className="border border-b-0 w-full p-4 flex justify-center">
      <button onClick={toggleSelection} className="w-8 absolute top-4 right-3">
        <img
          src={isSelectionActive ? closeIcon : returnIcon}
          alt=""
          className="rounded-full w-full"
        />
      </button>
      <Tabs defaultValue={value} className="w-[400px]">
        <TabsList
          className={`grid w-full content-center bg-transparent ${
            isSelectionActive ? "grid-cols-1 p-0" : "grid-cols-2"
          } `}
        >
          {isSelectionActive ? (
            <Select
              open={isSelectOpen}
              onOpenChange={(open) => setIsSelectOpen(open)}
              onValueChange={handleSubmit}
              disabled={selectedIds.length === 0}
            >
              <SelectTrigger
                className={`w-full rounded-3xl px-6 py-2 flex relative ${
                  selectedIds.length > 0 ? "bg-[#3643BA] text-white" : ""
                } ${isSelectOpen ? "rounded-b-none" : ""} `}
              >
                <SelectValue placeholder="Statü Belirle" />{" "}
                <span className="absolute right-12 text-xs">
                  Seçili Sipariş({selectedIds.length})
                </span>
              </SelectTrigger>
              <SelectContent className="rounded-3xl py-2 top-[-5px] rounded-t-none max-w-[400px]">
                {(get("claims").data.email || checkRole === "Admin") && (
                  <>
                    <SelectItem value="0">Oluşturuldu</SelectItem>
                    <SelectItem value="1">Üretiliyor</SelectItem>
                    <SelectItem value="2">Sevk Aşamasında</SelectItem>
                  </>
                )}
                {(checkRole === "warehouse" || checkRole === "Admin") && (
                  <>
                    <SelectItem value="3">Depoda</SelectItem>
                    <SelectItem value="4">Dağıtımda</SelectItem>
                  </>
                )}
                {(checkRole === "store" || checkRole === "Admin") && (
                  <>
                    <SelectItem value="5">Onay Bekliyor</SelectItem>
                    <SelectItem value="7">Tamamlandı</SelectItem>
                    <SelectItem value="6" className="text-red-600">
                      Tamamlanmadı
                    </SelectItem>
                  </>
                )}
              </SelectContent>
            </Select>
          ) : (
            <>
              <TabsTrigger
                value="ordersInProgress"
                className={`border rounded-full p-2 py-3 text-xs md:text-sm ${
                  !tabChange ? "!bg-[#3643BA] !text-white" : ""
                }`}
                ref={myElementRef}
                onClick={() => {
                  setValue("ordersInProgress");
                }}
              >
                Devam Eden Siparişler
                <span
                  className={`rounded-full font-bold w-[20px] h-[20px] text-xs content-center ml-1  ${
                    !tabChange
                      ? "text-[#3643BA] bg-white"
                      : "bg-[#999999] text-white"
                  } `}
                >
                  {pendingOrders?.length || 0}
                </span>
              </TabsTrigger>
              <TabsTrigger
                value="completedOrders"
                className={`border rounded-full p-2 py-3 text-xs md:text-sm ${
                  tabChange ? "!bg-[#3643BA] !text-white" : ""
                }`}
                onClick={() => {
                  setValue("completedOrders");
                }}
              >
                Tamamlanan Siparişler
                <span
                  className={`rounded-full font-bold w-[20px] h-[20px] text-xs content-center ml-1  ${
                    tabChange
                      ? "text-[#3643BA] bg-white"
                      : "bg-[#999999] text-white"
                  } `}
                >
                  {completeOrders?.length}
                </span>
              </TabsTrigger>
            </>
          )}
        </TabsList>
        <div className="relative">
          <div className="my-4 h-9">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute h-full left-4 top-1"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.30039 2.60411C5.69745 2.60411 2.70039 5.60163 2.70039 9.20513C2.70039 12.8086 5.69745 15.8062 9.30039 15.8062C12.9033 15.8062 15.9004 12.8086 15.9004 9.20513C15.9004 5.60163 12.9033 2.60411 9.30039 2.60411ZM0.900391 9.20513C0.900391 4.60736 4.70333 0.803833 9.30039 0.803833C13.8974 0.803833 17.7004 4.60736 17.7004 9.20513C17.7004 13.8029 13.8974 17.6064 9.30039 17.6064C4.70333 17.6064 0.900391 13.8029 0.900391 9.20513ZM17.1368 15.8698L21.0368 19.7704L19.764 21.0434L15.864 17.1428L17.1368 15.8698Z"
                fill="#616161"
              />
            </svg>
            <input
              type="text"
              className={`border w-full p-2 rounded-3xl pl-11 bg-[#F5F4F5] ${
                suggestions.length > 0 ? "" : ""
              }`}
              placeholder="Sipariş No veya Ürün Adı ile Ara"
              value={filter}
              onChange={handleFilterChange}
            />
            {suggestionsSelect && suggestions.length > 0 ? (
              <div
                className="animate-fadeIn suggestions-list bg-white border rounded-3xl p-4 z-10 relative"
                style={{ transition: "background 2s ease" }}
              >
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion.orderNumber}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="suggestion-item my-2"
                  >
                    {suggestion.orderNumber} - {suggestion.supplierEmail}
                  </div>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <TabsContent value="ordersInProgress">
          {filteredOrders ? (
            filteredOrders?.map((order, index) => (
              <OrderCard
                key={order.orderNumber}
                orderInfo={order}
                isSelected={selectedIds.includes(order.orderNumber)}
                handleCardClick={handleCardClick}
                isOpen={expandedIds.includes(order.id)}
                toggleAccordion={toggleAccordion}
                isSelectionActive={isSelectionActive}
                refreshOrders={refreshOrders}
              ></OrderCard>
            ))
          ) : (
            <>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </>
          )}
        </TabsContent>
        <TabsContent value="completedOrders">
          {completedFilteredOrders ? (
            completedFilteredOrders?.map((order, index) => (
              <OrderCard
                key={order.orderNumber}
                orderInfo={order}
                isSelected={selectedIds.includes(order.orderNumber)}
                handleCardClick={handleCardClick}
                isOpen={expandedIds.includes(order.id)}
                toggleAccordion={toggleAccordion}
                isSelectionActive={isSelectionActive}
                refreshOrders={refreshOrders}
              ></OrderCard>
            ))
          ) : (
            <>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </>
          )}

          {checkRole === "Admin" && (
            <div
              className="fixed left-0 bottom-8 w-full"
              style={{ textAlign: "-webkit-center" }}
            >
              <button
                className="border flex bg-[#3643BA] rounded-3xl px-6 py-2 text-white items-center gap-2"
                onClick={handleCSV}
              >
                <img src={csvDownload} alt="" />
                İndir (CSV)
              </button>
            </div>
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
}
